import React from "react";

import { Link } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';
import Meta from '../components/meta';

export default () => 
  <div className="vh-100">
    <Meta title="404" />
    <Header darkFont={true}></Header>
    <div className="p-5 off-white-background container-fluid">
      <div className="row justify-content-center py-5">
        <div className="col-12 align-self-center text-center p-5">
          <p className="d-block d-md-none text-dark sans-serif logo-font">VANCOUVER JADE</p>
          <h1 className="text-dark serif display-1 pt-5">404</h1>
          <h2 className="text-dark sans-serif">Page Not Found</h2>
          <Link to="/"><button className="btn btn-outline-dark btn-sm thick-border rounded-0 mt-4 py-1 px-5 sans-serif">HOME</button></Link>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
